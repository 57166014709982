import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

import Form from '../components/form'

import kv from '../images/contact-kv.jpg'

const Contact = ({ location }) => (
  <Layout location={location} title="Contact" subTitle="お問い合わせ" kv={kv} kvPositionX="65%">
    <SEO title="お問い合わせ" />
    <section className="global-form">
      <Form showCompany={false} showAge={false} messageLabel="お問い合わせ" from="お問い合わせ" />
    </section>
  </Layout>
)

export default Contact
