import React from 'react'

const Form = ({showCompany = true, showAge = true, messageLabel = 'メッセージ', from}) => (
  <form className="formrun" action="https://form.run/api/v1/r/ks160hnnyva08foqqrtk88v0" method="post">

    {showCompany && 
      <div className="form-item">
        <label>会社名</label>
        <input name="会社名" type="text" placeholder="株式会社スマートホーム" />
      </div>
    }

    <div className="form-item">
      <label className="required">名前</label>
      <input name="名前" type="text" placeholder="田中 太郎" data-formrun-required />
    </div>

    {showAge && 
      <div className="form-item">
        <label>年齢</label>
        <div>
          <input className="age" name="年齢" type="number" placeholder="30" data-formrun-type="number" max="100" min="0" />歳
        </div>
      </div>
    }

    <div className="form-item">
      <label className="required">メールアドレス</label>
      <input name="メールアドレス" type="text" placeholder="taro.tanaka@smarthome.jp" data-formrun-type="email" data-formrun-required />
    </div>

    <div className="form-item">
      <label className="required">電話番号</label>
      <input type="number" name="電話番号" data-formrun-type="tel" placeholder="08012345678" data-formrun-required />
    </div>

    <div className="form-item">
      <label className="required">{messageLabel}</label>
      <textarea rows="6" type="text" name="メッセージ" data-formrun-required></textarea>
    </div>

    <div className="_formrun_gotcha">
      <label htmlFor="_formrun_gotcha">If you are a human, ignore this field</label>
      <input type="text" name="_formrun_gotcha" id="_formrun_gotcha" tabIndex="-1" />
    </div>

    <div className="form-item dn">
      <input name="問い合わせ種別" type="text" value={from} data-formrun-required />
    </div>

    <div className="button-container">
      <button className="button primary" type="submit" data-formrun-error-text="未入力の項目があります" data-formrun-submitting-text="送信中...">送信</button>
    </div>
    <script src="https://sdk.form.run/js/v2/formrun.js"></script>
  </form>
)

export default Form